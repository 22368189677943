$(document)
  .on('click', '.package-container-wrapper .package-container .btn--view-more', function(event) {
    event.preventDefault()
    if(event.target.nodeName == 'ION-ICON'){
      $(event.target).parent().parent().addClass('hidden')
      $(event.target).parent().parent().parent().find('.blue-box--content').addClass('show-content')
    } else {
      $(event.target).parent().parent().addClass('hidden')
      $(event.target).parent().parent().parent().find('.blue-box--content').addClass('show-content')
    }

    return false
  })

  .on('click', '.package-container-wrapper .package-container .blue-box--content .btn--close', function(event) {
    event.preventDefault()
    $(event.target).parent().parent().parent().find('.blue-box-original-content').removeClass('hidden')
    $(event.target).parent().removeClass('show-content')
    return false
  })

  .on('click', '.package-container-wrapper .package-container .blue-box--content', function(event) {
    if(event.target.className === 'btn btn-primary' ) { return }
    event.preventDefault()
    return false
  })
